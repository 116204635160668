<template>
  <div class="ctx-job-create px-8">
    <v-form
      ref="createTicketForm"
      v-model="formValid"
      lazy-validation
      v-on:submit.stop.prevent="updateOrCreate()"
    >
      <template>
        <v-row>
          <v-col md="7">
            <p class="ctx-job-title">
              Visit For
              <span
                v-if="vCustomerId"
                v-on:click="customerDialog = true"
                class="ctx-job-client-name"
                ><template v-if="vCustomer?.company_name"
                  >{{ vCustomer?.company_name }}
                </template>
                <template v-else>{{ fullname() }} </template></span
              >
              <span
                v-else
                v-on:click="customerDialog = true"
                class="ctx-job-client-name"
                >Select a Customer
                <v-icon large color="cyan"
                  >mdi-plus-circle-outline</v-icon
                ></span
              >
            </p>
          </v-col>
          <v-col md="5" class="text-right">
            <v-btn
              class="mr-2 custom-grey-border custom-bold-button"
              depressed
              :disabled="loaderLoding"
              v-on:click="goBack()"
              >Cancel</v-btn
            >
            <v-btn
              class="ml-2 custom-bold-button"
              depressed
              :disabled="loaderLoding"
              :loading="loaderLoding"
              v-on:click="updateOrCreate()"
              color="cyan white--text"
              >Save</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col md="7">
            <label class="ml-2 required" :for="`ctx-job-title-${uniqueId}`"
              >Title</label
            >
            <TextField
              :id="`ctx-job-title-${uniqueId}`"
              dense
              filled
              placeholder="Title"
              solo
              flat
              counter="200"
              v-model="jobCreate.title"
              color="cyan"
              :maxlength="250"
              :rules="[validateRules.required(jobCreate.title, 'Title')]"
            />
            <!--    <v-text-field
            :id="`ctx-job-title-${uniqueId}`"
            dense
            filled
            placeholder="Title"
            solo
            flat
            :disabled="jobLoading"
            v-model="jobCreate.title"
            color="cyan"
            class="mb-2"
            :rules="[validateRules.required(jobCreate.title, 'Title')]"
          /> -->
            <label class="ml-2" :for="`ctx-job-description-${uniqueId}`"
              >Description</label
            >
            <v-textarea
              :id="`ctx-job-description-${uniqueId}`"
              auto-grow
              dense
              filled
              color="cyan"
              placeholder="Description"
              solo
              flat
              :disabled="jobLoading"
              v-model="jobCreate.description"
              row-height="25"
              class="mb-2"
            />
            <label class="ml-2" :for="`ctx-job-remark-${uniqueId}`"
              >Remark</label
            >
            <v-textarea
              :id="`ctx-job-remark-${uniqueId}`"
              auto-grow
              dense
              filled
              :disabled="jobLoading"
              color="cyan"
              placeholder="Remark"
              solo
              v-model="jobCreate.remark"
              flat
              row-height="25"
              class="mb-2"
            />
          </v-col>
          <v-col md="5">
            <table width="100%">
              <tr>
                <td width="200px">
                  <label :for="`ctx-job-visit-number-${uniqueId}`"
                    >Visit Number</label
                  >
                </td>
                <td>
                  <v-text-field
                    :id="`ctx-job-visit-number-${uniqueId}`"
                    dense
                    filled
                    class="font-weight-700"
                    :disabled="jobLoading"
                    readonly
                    :value="
                      jobEditData?.visit?.barcode ?? jobOption.visit_barcode
                    "
                    placeholder="Visit Number"
                    solo
                    flat
                    color="cyan"
                  />
                </td>
              </tr>
              <tr>
                <td width="200px">
                  <label :for="`ctx-job-priority-${uniqueId}`">Priority</label>
                </td>
                <td>
                  <div class="d-flex ml-1">
                    <v-btn
                      depressed
                      tile
                      :disabled="jobLoading"
                      :outlined="!(jobCreate.priority == 1)"
                      v-on:click="jobCreate.priority = 1"
                      value="1"
                      color="red darken-3 white--text"
                      >High</v-btn
                    >
                    <v-btn
                      class="mx-2"
                      depressed
                      tile
                      :disabled="jobLoading"
                      :outlined="!(jobCreate.priority == 2)"
                      v-on:click="jobCreate.priority = 2"
                      value="2"
                      color="orange darken-4 white--text"
                      >Medium</v-btn
                    >
                    <v-btn
                      depressed
                      tile
                      :disabled="jobLoading"
                      :outlined="!(jobCreate.priority == 3)"
                      v-on:click="jobCreate.priority = 3"
                      value="3"
                      color="light-green darken-3 white--text"
                      >Low</v-btn
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td width="200px">
                  <label :for="`ctx-job-amount-to-be-collected-${uniqueId}`"
                    >Amount to be collected</label
                  >
                </td>
                <td>
                  <v-text-field
                    :id="`ctx-job-amount-to-be-collected-${uniqueId}`"
                    dense
                    filled
                    :disabled="jobLoading"
                    placeholder="Amount to be collected"
                    solo
                    flat
                    type="number"
                    v-model="jobCreate.collected_amount"
                    v-on:keypress="
                      limitDecimal($event, jobCreate.collected_amount)
                    "
                    color="cyan"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label
                    class="mb-0"
                    :for="`ctx-job-service-location-${uniqueId}`"
                  >
                    Service Location
                    <v-icon
                      :disabled="jobLoading"
                      v-on:click="propertyDialog = true"
                      class="mx-4"
                      small
                      color="cyan"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon small color="cyan">mdi-history</v-icon>
                  </label>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <p v-if="vPropertyId" class="mb-0">
                    {{ vProperty?.property_address }}
                  </p>
                  <p v-else-if="vCustomerId" class="mb-0 red--text">
                    Please select service location
                  </p>
                  <p v-else class="mb-0 red--text">Please select customer</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label
                    class="mb-0"
                    :for="`ctx-job-contact-details-${uniqueId}`"
                    >Contact Details</label
                  >
                </td>
              </tr>
              <tr v-if="vPropertyContactId">
                <td colspan="2">
                  <p class="mb-1">
                    {{ vPropertyContact?.first_name }}
                    {{ vPropertyContact?.last_name }}
                  </p>
                  <p class="mb-1">{{ vPropertyContact?.primary_phone }}</p>
                  <p class="mb-1">{{ vPropertyContact?.primary_email }}</p>
                </td>
              </tr>
              <tr v-else-if="vCustomerId">
                <td colspan="2">
                  <p class="mb-0 red--text">Please select contact person</p>
                </td>
              </tr>
              <tr v-else>
                <td colspan="2">
                  <p class="mb-0 red--text">Please select customer</p>
                </td>
              </tr>
              <tr>
                <td>
                  <label :for="`ctx-job-email-notification-${uniqueId}`"
                    >Email Notification</label
                  >
                </td>
                <td>
                  <v-switch
                    v-model="jobCreate.email_notify"
                    :true-value="1"
                    :false-value="0"
                    :disabled="jobLoading"
                    class="m-0 p-0"
                    color="cyan"
                    dense
                    inset
                  />
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="12" class="job-schedule pb-0">
            <v-tabs
              v-if="!isEditPage"
              v-model="jobCreate.schedule_tab"
              background-color="transparent"
              centered
              :disabled="jobLoading"
              color="cyan"
              icons-and-text
              class="mb-3"
              v-on:change="updateScheduleType($event)"
            >
              <v-tab
                :disabled="jobLoading"
                href="#one-off"
                style="min-width: 390px"
              >
                <div class="custom-tab-title d-flex">
                  <div class="custom-tab-icon pr-2">
                    <v-icon left large color="gray">mdi-calendar</v-icon>
                  </div>
                  <div class="text-left">
                    <h3 class="text-uppercase font-weight-700">One-Off Job</h3>
                    <p class="m-0 text-capitalize">
                      A one time job with one or more visits
                    </p>
                  </div>
                </div>
              </v-tab>
              <v-tab
                :disabled="jobLoading"
                href="#recurring"
                style="min-width: 390px"
              >
                <div class="custom-tab-title d-flex">
                  <div class="custom-tab-icon pr-2">
                    <v-icon left large color="gray"
                      >mdi-calendar-multiple</v-icon
                    >
                  </div>
                  <div class="text-left">
                    <h3 class="text-uppercase font-weight-700">
                      Recurring Job
                    </h3>
                    <p class="m-0 text-capitalize">
                      A contract job with repeating visits
                    </p>
                  </div>
                </div>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-if="(isEditPage && isEditLoaded) || !isEditPage"
              v-model="jobCreate.schedule_tab"
              class="remvoe-overflow"
            >
              <v-tab-item value="one-off"> </v-tab-item>
              <v-tab-item value="recurring"> </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col md="12">
            <v-expansion-panels
              :disabled="jobLoading"
              flat
              tile
              multiple
              v-model="panel"
            >
              <v-expansion-panel
                v-if="jobCreate.schedule_tab == 'one-off'"
                class="mb-4 custom-border"
              >
                <v-expansion-panel-header color="grey lighten-4">
                  <div class="my-3">
                    <h3
                      class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                    >
                      One-Off Job
                    </h3>
                    <p class="m-0 text-capitalize">
                      A one time job with one or more visits 1
                    </p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <OneOffSchedule
                      :key="`job-one-off-schedule-${scheduleKey}`"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="jobCreate.schedule_tab == 'recurring'"
                class="mb-4 custom-border"
              >
                <v-expansion-panel-header color="grey lighten-4">
                  <div class="my-3">
                    <h3
                      class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                    >
                      Recurring Job
                    </h3>
                    <p class="m-0 text-capitalize">
                      A contract job with repeating visits
                    </p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <RecurringSchedule
                      :key="`job-recurring-schedule-${scheduleKey}`"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-4 custom-border">
                <v-expansion-panel-header color="grey lighten-4">
                  <div class="my-3">
                    <h3
                      class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                    >
                      Line Items ({{ liSelectedLineItem.length }})
                    </h3>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <div>
                    <LineItem :key="`job-line-item-${uniqueId}`" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-4 custom-border">
                <v-expansion-panel-header color="grey lighten-4">
                  <div class="my-3">
                    <h3
                      class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                    >
                      Terms &amp; Conditions
                    </h3>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="py-3">
                  <div class="px-4 py-4">
                    <TinyMCE
                      :key="`job-terms-conditions-${uniqueId}`"
                      v-model="jobCreate.term_conditions"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-4 custom-border">
                <v-expansion-panel-header color="grey lighten-4">
                  <div class="my-3">
                    <h3
                      class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                    >
                      Internal Notes &amp; Attachments
                    </h3>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pt-4">
                    <v-row>
                      <v-col class="px-6" md="6">
                        <label :for="`job-client-note-${uniqueId}`"
                          >Client Notes</label
                        >
                        <TinyMCE
                          :key="`job-client-note-${uniqueId}`"
                          :id="`job-client-note-${uniqueId}`"
                          v-model="jobCreate.client_remark"
                        />
                      </v-col>
                      <v-col class="px-6" md="6">
                        <label :for="`job-admin-note-${uniqueId}`"
                          >Admin Notes</label
                        >
                        <TinyMCE
                          :key="`job-admin-note-${uniqueId}`"
                          :id="`job-admin-note-${uniqueId}`"
                          v-model="jobCreate.admin_remark"
                        />
                      </v-col>
                      <v-col md="6">
                        <label class="ml-4">Attachments</label>
                        <table width="100%">
                          <tbody>
                            <tr
                              v-for="(row, index) in jobCreate.attachments"
                              :key="`prev-attach-${index}`"
                            >
                              <td width="50%" class="pr-2 py-2">
                                <v-text-field
                                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                  placeholder="File Name"
                                  dense
                                  solo
                                  flat
                                  :disabled="jobLoading"
                                  readonly
                                  color="cyan"
                                  v-model="row.name"
                                  hide-details
                                ></v-text-field>
                              </td>
                              <td width="50%" class="pl-2 py-2">
                                <v-text-field
                                  placeholder="File Name"
                                  dense
                                  solo
                                  flat
                                  color="cyan"
                                  v-model="row.name"
                                  hide-details
                                  :disabled="jobLoading"
                                  class="has-delete-outer-icon"
                                  append-outer-icon="mdi-delete"
                                  v-on:click:append-outer="
                                    removeRow('jobCreate.attachments', index)
                                  "
                                  :suffix="`.${row.ext}`"
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr
                              v-for="(row, index) in attachments"
                              :key="index"
                            >
                              <td width="50%" class="pr-2 py-2">
                                <v-file-input
                                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                  placeholder="Click here to select file"
                                  prepend-icon=""
                                  color="cyan"
                                  solo
                                  :disabled="jobLoading"
                                  flat
                                  v-model="row.accepted_file"
                                  dense
                                  v-on:change="updateFileName(index)"
                                  hide-details
                                ></v-file-input>
                              </td>
                              <td width="50%" class="pl-2 py-2">
                                <v-text-field
                                  placeholder="File Name"
                                  dense
                                  solo
                                  flat
                                  :disabled="jobLoading"
                                  color="cyan"
                                  v-model="row.file_name"
                                  hide-details
                                  class="has-delete-outer-icon"
                                  append-outer-icon="mdi-delete"
                                  v-on:click:append-outer="
                                    removeRow('attachments', index)
                                  "
                                  :suffix="getFileExtension(row.file_type)"
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <v-btn
                                  color="cyan white--text"
                                  small
                                  :disabled="jobLoading"
                                  depressed
                                  v-on:click="addAttachmentRow()"
                                  class="ml-1 custom-bold-button"
                                  >Add More...</v-btn
                                >
                              </td>
                            </tr>
                            <tr>
                              <td height="10px" colspan="2"></td>
                            </tr>
                          </tbody>
                        </table>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </template>
    </v-form>
    <CustomerSelect
      :key="`job-customer-select-${dailogKey}`"
      :dialog="customerDialog"
      v-on:close="customerDialog = false"
    />

    <PersonSelect
      :key="`job-person-select-${dailogKey}`"
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="contactType"
    />

    <AddressSelect
      :key="`job-address-select-${dailogKey}`"
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      type="property"
      label="Service Location"
      placeholder="Service Location"
    />

    <template>
      <Dialog :common-dialog="confirmDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <div
              v-if="updateScheduleChanged"
              class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
              >
                <v-icon color="red darken-3" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Attention! Schedule has been Changed1.
              </p>
            </div>

            <div
              v-if="updateTeamChanged"
              class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
              >
                <v-icon color="red darken-3" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Attention! Team has been Changed.
              </p>
            </div>

            <v-radio-group v-model="updateTargetType" row>
              <v-radio
                color="cyan"
                label="Update the current visit only?"
                value="self"
              ></v-radio>
              <v-radio
                color="cyan"
                label="Update all upcoming visits?"
                value="upcomming"
              ></v-radio>
            </v-radio-group>

            <div
              v-if="updateTargetType == 'upcomming'"
              class="p-4 mt-4 font-size-16 font-weight-400 warn-warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 orange--text text--darken-4"
              >
                <v-icon color="orange darken-4" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Note: The visits scheduled ahead will be changed with a new
                SCHEDULE and TEAM. Visit # will be updated accordingly.
              </p>
            </div>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            :disabled="jobLoading"
            v-on:click="
              scheduleChangeConfirmed = true;
              updateOrCreate();
            "
          >
            Continue
          </v-btn>
          <v-btn
            :disabled="jobLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="confirmDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="vDupDialog" :dialog-width="dialogWidth">
        <template v-slot:title>Visit on Same Postal Code</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <table width="100%" class="white-background">
              <thead>
                <tr>
                  <td
                    width="5%"
                    class="border-bottom cyan lighten-4"
                    align="center"
                    valign="center"
                  >
                    <v-checkbox
                      v-on:change="dupCheckAll($event)"
                      color="cyan"
                      small
                      dense
                      hide-details
                      class="hide-margin m-0 p-0"
                    />
                  </td>
                  <td
                    class="border-bottom cyan lighten-4 black--text font-weight-600"
                    colspan="4"
                  >
                    VISIT DETAIL
                  </td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(row, index) in vDupVisit">
                  <tr
                    :key="`dup-key-title-${index}`"
                    :class="{ 'orange lighten-5': index % 2 }"
                  >
                    <td rowspan="2" width="5%" align="center" valign="center">
                      <v-checkbox
                        v-model="row.status"
                        color="cyan"
                        small
                        dense
                        hide-details
                        class="m-0 p-0"
                      />
                    </td>
                    <td colspan="3" v-on:click="row.status = !row.status">
                      <p class="my-0">
                        <span class="font-weight-600">Title : </span
                        ><span class="black--text font-weight-600">{{
                          row.title
                        }}</span>
                      </p>
                      <p class="my-0">
                        <span class="font-weight-600">Customer : </span
                        ><span class="black--text font-weight-600">{{
                          row.customer
                        }}</span>
                      </p>
                      <p class="my-0">
                        <span class="font-weight-600">Property : </span
                        ><span class="black--text font-weight-600">{{
                          row.property_address
                        }}</span>
                      </p>
                    </td>
                  </tr>
                  <tr
                    :key="`dup-key-basic-${index}`"
                    class="cursor-pointer"
                    v-on:click="row.status = !row.status"
                    :class="{ 'orange lighten-5': index % 2 }"
                  >
                    <td class="pt-0" width="15%">
                      <Barcode small :barcode="row.barcode" />
                    </td>
                    <td class="pt-0" width="15%">
                      <CustomStatus
                        small
                        :status="row.status_id"
                        endpoint="visit/status"
                      />
                    </td>
                    <td class="pt-0">
                      <template v-if="!lodash.isEmpty(row.ticket_engineers)">
                        <template
                          v-for="(engineer, index) in row.ticket_engineers"
                        >
                          <template v-if="index < 3">
                            <v-tooltip
                              top
                              content-class="custom-top-tooltip"
                              :key="'engineer-tooltip' + index"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  v-on="on"
                                  v-bind="attrs"
                                  width="35"
                                  height="35"
                                  min-width="35"
                                  class="mr-1"
                                  color="grey lighten-2 grey--text"
                                  style="
                                    margin-left: -12px;
                                    border: 2px solid #fff !important;
                                  "
                                  :key="index"
                                >
                                  <template
                                    v-if="engineer.profile_logo != null"
                                  >
                                    <v-img
                                      :src="engineer.profile_logo + '?fafa'"
                                    ></v-img>
                                  </template>
                                  <template v-else>
                                    <span class="fs-15">{{
                                      engineer.display_name
                                        .slice(0, 2)
                                        .toUpperCase()
                                    }}</span>
                                  </template>
                                </v-avatar>
                              </template>
                              <span>{{ engineer.display_name }}</span>
                            </v-tooltip>
                          </template>
                          <template v-else>
                            +{{
                              row &&
                              row.ticket_engineers &&
                              row.ticket_engineers.length - 3
                            }}
                            more...
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <p
                          class="m-0 max-content-width text-truncate font-weight-600 text--secondary"
                        >
                          Not Assigned Yet.
                        </p>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="includeIntoRemark('all')"
          >
            Include All into Remark
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan darken-4"
            v-on:click="includeIntoRemark('selected')"
          >
            Include Selected into Remark
          </v-btn>
          <v-btn
            v-on:click="closeDupDialog()"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="alertDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <div
              class="p-4 mt-4 font-size-16 font-weight-400 warn-warning-background"
            >
              <p
                class="mb-0 d-flex align-center font-weight-600 orange--text text--darken-4"
              >
                <v-icon color="orange darken-4" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Note: If you create more than 50 visits, the system may take up
                to 10 minutes to process. During this time, please refrain from
                making any changes. After the processing is complete, you will
                be able to use the visits.
              </p>
            </div>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            :disabled="jobLoading"
            v-on:click="
              updateAlertDialog = true;
              updateOrCreate();
            "
          >
            Continue
          </v-btn>
          <v-btn
            :disabled="jobLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="alertDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from "vuex";
import TextField from "@/view/pages/partials/Detail/TextField.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import OneOffSchedule from "@/view/components/OneOffSchedule.vue";
import RecurringSchedule from "@/view/components/RecurringSchedule.vue";
import LineItem from "@/view/components/LineItem.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import {
  CLEAR_TICKET,
  GET_TICKET,
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  CREATE_OR_UPDATE,
  SET_LOADING,
} from "@/core/services/store/job.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageJob from "@/core/plugins/job.plugin";
import { UPLOAD } from "@/core/services/store/request.module";
import {
  SET_EDIT_MODE,
  CLOSE_DUP_DIALOG,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/customer.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import { QUERY } from "@/core/services/store/request.module";

export default {
  mixins: [ValidationMixin],
  name: "job-ctx-create",
  title: "Create Job",
  data() {
    return {
      alertDialog: false,
      panel: [0],
      loaderLoding: false,
      confirmDialog: false,
      updateScheduleChanged: false,
      scheduleChangeConfirmed: false,
      updateTeamChanged: false,
      updateAlertDialog: false,
      updateTargetType: "self",
      ticketId: 0,
      visitId: 0,
      duplicateTicketId: 0,
      duplicateVisitId: 0,
      uniqueId: Number(new Date()),
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      formValid: true,
      customerDialog: false,
      contactDialog: false,
      propertyDialog: false,
      contactType: null,
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      isEditPage: false,
      isEditLoaded: false,
      jobCreate: {
        schedule_tab: "one-off",
        priority: 3,
        title: null,
        description: null,
        remark: null,
        barcode: null,
        collected_amount: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        attachments: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "defaultStartedAt",
      "defaultFinishedAt",
      "vDupDialog",
      "vDupVisit",
      "jobEditData",
      "jobOption",
      "liCalculation",
      "liSelectedLineItem",
      "scheduleOutput",
      "oneOffSchedule",
      "recurringSchedule",
      "assignedTeam",
      "jobLoading",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  components: {
    Barcode,
    Dialog,
    TinyMCE,
    LineItem,
    CustomStatus,
    RecurringSchedule,
    OneOffSchedule,
    CustomerSelect,
    AddressSelect,
    PersonSelect,
    TextField,
  },
  methods: {
    fullname() {
      let name = "";
      const _this = this;
      if (
        this.vCustomer &&
        _this.lodash.isEmpty(this.vCustomer.default_person) === false
      ) {
        name =
          _this.lodash.startCase(this.vCustomer?.default_person?.title) +
          ". " +
          this.vCustomer?.default_person?.first_name +
          " " +
          this.vCustomer?.default_person?.last_name;
      }
      return name;
    },
    includeIntoRemark(type) {
      let remark = [];
      if (type == "all") {
        remark = this.vDupVisit.map((row) => row.barcode);
      }
      if (type == "selected") {
        remark = this.vDupVisit.filter((row) => row.status);
        if (!remark.length) {
          ErrorEventBus.$emit("update:error", "Select Atleast One Visit");
          return false;
        }
        remark = remark.map((row) => row.barcode);
      }
      if (remark.length) {
        this.jobCreate.remark = remark.join(", ");
      }
      this.closeDupDialog();
    },
    closeDupDialog() {
      for (let i = 0; i < this.vDupVisit.length; i++) {
        this.vDupVisit[i].status = false;
      }
      this.$store.commit(CLOSE_DUP_DIALOG);
    },
    dupCheckAll(param) {
      for (let i = 0; i < this.vDupVisit.length; i++) {
        this.vDupVisit[i].status = param;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    removeRow(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    updateFileName(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.addAttachmentRow();
      }
    },
    async updateOrCreate() {
      try {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Customer.")
          );
          return false;
        }

        if (!this.vPropertyId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Service Location.")
          );
          return false;
        }

        if (!this.vPropertyContactId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Contact Person.")
          );
          return false;
        }

        if (this.jobCreate.schedule_tab == "recurring") {
          if (!this.recurringSchedule.message) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Oops... Dates are not available, Please select valid schedule.."
              )
            );
            return false;
          }
        }

        if (!this.liSelectedLineItem || !this.liSelectedLineItem.length) {
          ErrorEventBus.$emit("update:error", "Select Line Item");
          return false;
        }

        const validateStatus = this.$refs.createTicketForm.validate();

        const formErrors = this.validateForm(this.$refs.createTicketForm);

        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        if (
          !this.scheduleChangeConfirmed &&
          this.isEditPage &&
          this.jobCreate.schedule_tab == "recurring"
        ) {
          this.updateScheduleChanged = false;
          this.updateTeamChanged = false;
          this.confirmDialog = false;

          const jobInstance = new ManageJob();

          if (
            !jobInstance.scheduleCompare(
              this.jobEditData,
              this.recurringSchedule
            )
          ) {
            this.confirmDialog = true;
            this.updateScheduleChanged = true;
            if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
              this.updateTeamChanged = true;
            }
            return false;
          }

          if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
            this.confirmDialog = true;
            this.updateTeamChanged = true;
            return false;
          }
        }

        if (
          this.jobCreate.schedule_tab == "recurring" &&
          !this.updateAlertDialog &&
          this.visitId == 0
        ) {
          this.alertDialog = false;
          if (this.scheduleOutput?.length > 50) {
            this.alertDialog = true;
            return false;
          }
        }

        if (
          this.jobCreate.schedule_tab == "recurring" &&
          !this.updateAlertDialog &&
          this.visitId > 0 &&
          this.updateTargetType == "upcomming"
        ) {
          this.alertDialog = false;
          if (this.scheduleOutput?.length > 50) {
            this.alertDialog = true;
            return false;
          }
        }
        this.loaderLoding = true;
        this.$store.commit(SET_LOADING, true);
        const new_attachments = await this.uploadFiles();

        const request = {
          ...this.jobCreate,
          default_started_at: this.defaultStartedAt,
          default_finished_at: this.defaultFinishedAt,
          update_schedule_changed: +this.updateScheduleChanged,
          update_team_changed: +this.updateTeamChanged,
          update_target_type: this.updateTargetType,
          attachments: [...this.jobCreate.attachments, ...new_attachments],
          ticket: this.ticketId,
          visit: this.visitId,
          customer: this.vCustomerId,
          property: this.vPropertyId,
          property_contact: this.vPropertyContactId,
          billing: this.vBillingId,
          billing_contact: this.vBillingContactId,
          calculation: this.liCalculation,
          lineitem: this.liSelectedLineItem,
          team: this.assignedTeam,
          clear_engineers: +!this.assignedTeam.length,
          oneoff:
            this.jobCreate.schedule_tab == "one-off" ? this.oneOffSchedule : {},
          recurring:
            this.jobCreate.schedule_tab == "recurring"
              ? this.recurringSchedule
              : {},
          schedule:
            this.jobCreate.schedule_tab == "recurring"
              ? this.scheduleOutput
              : [],
        };

        this.$store
          .dispatch(CREATE_OR_UPDATE, request)
          .then(() => {
            this.loaderLoding = false;
            this.$store.commit(SET_LOADING, false);
            this.$router.replace({
              name: "admin.visit",
              query: { status: "all" },
            });
          })
          .catch(() => {
            this.loaderLoding = false;
            this.$store.commit(SET_LOADING, false);
          });
      } catch (error) {
        this.loaderLoding = false;
        this.$store.commit(SET_LOADING, false);
        console.log(error);
      }
    },
    updateScheduleType(param) {
      this.scheduleKey = Number(new Date());
      this.$store.commit(SET_SCHEDULE_TYPE, param);
    },
    getTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.ticketId,
          visit: this.visitId,
        })
        .then(() => {
          this.isEditLoaded = true;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Update" },
            { title: this.jobEditData?.visit?.barcode },
          ]);

          this.jobCreate = {
            schedule_tab:
              this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off",
            priority: this.jobEditData?.ticket?.priority,
            title: this.jobEditData?.ticket?.title,
            description: this.jobEditData?.ticket?.description,
            remark: this.jobEditData?.ticket?.additional_remarks,
            barcode: this.jobEditData?.ticket?.barcode,
            collected_amount: this.jobEditData?.ticket?.amount_to_be_collected,
            term_conditions: this.jobEditData?.ticket?.term_conditions,
            client_remark: this.jobEditData?.ticket?.client_remark,
            admin_remark: this.jobEditData?.ticket?.admin_remark,
            email_notify: this.jobEditData?.ticket?.notify_engineer,
            attachments: [],
          };
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getDuplicateTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.duplicateTicketId,
          visit: this.duplicateVisitId,
        })
        .then(() => {
          this.jobCreate.schedule_tab =
            this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off";
          this.jobCreate.priority = this.jobEditData?.ticket?.priority;
          this.jobCreate.title = this.jobEditData?.ticket?.title;
          this.jobCreate.description = this.jobEditData?.ticket?.description;
          this.jobCreate.remark = this.jobEditData?.ticket?.additional_remarks;
          this.jobCreate.collected_amount =
            this.jobEditData?.ticket?.amount_to_be_collected;
          this.jobCreate.term_conditions =
            this.jobEditData?.ticket?.term_conditions;
          this.jobCreate.client_remark =
            this.jobEditData?.ticket?.client_remark;
          this.jobCreate.admin_remark = this.jobEditData?.ticket?.admin_remark;
          this.jobCreate.email_notify =
            this.jobEditData?.ticket?.notify_engineer;
          this.jobCreate.attachments = [];
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
  },
  created() {
    this.ticketId = Number(this.$route?.query?.ticket ?? 0);
    this.visitId = Number(this.$route?.query?.visit ?? 0);
    this.duplicateTicketId = Number(
      this.$route?.query["duplicate-ticket"] ?? 0
    );
    this.duplicateVisitId = Number(this.$route?.query["duplicate-visit"] ?? 0);
  },
  destroyed() {
    this.$store.dispatch(CLEAR_TICKET);
  },
  mounted() {
    this.$store.dispatch(GET_OPTION);

    if (this.duplicateTicketId && this.duplicateVisitId) {
      this.getDuplicateTicket();
    }

    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }

    if (this.ticketId && this.visitId) {
      this.getTicket();
      this.isEditPage = true;
      this.$store.commit(SET_EDIT_MODE, true);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Create" },
      ]);
    }
  },
};
</script>
